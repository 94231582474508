import { SimpleServerResponse } from '@/store/types'
import i18n from '@/i18n'

class TextCreatorForSubmitSuccess<T extends object, K extends keyof T> {
  private readonly dataObject: T
  private messageRoot: string
  private readonly headlineFieldOnDataObject: K
  private readonly infoFieldOnDataObject: K
  private readonly headlineValue: string
  private optionalErrorMessageObject: object | undefined

  constructor (dataObject: T, messageRoot: string, optionalErrorMessageObject?: object, headlineFieldOnDataObject?: K, infoFieldOnDataObject?: K) {
    this.dataObject = dataObject
    this.messageRoot = messageRoot
    this.optionalErrorMessageObject = optionalErrorMessageObject

    if (headlineFieldOnDataObject === undefined || infoFieldOnDataObject === undefined) {
      if (TextCreatorForSubmitSuccess.isSimpleServerResponse(this.dataObject)) {
        this.headlineFieldOnDataObject = 'requestStatus' as K
        this.infoFieldOnDataObject = 'message' as K
      }
    } else {
      this.headlineFieldOnDataObject = headlineFieldOnDataObject
      this.infoFieldOnDataObject = infoFieldOnDataObject
    }

    this.headlineValue = TextCreatorForSubmitSuccess.prop(this.dataObject, this.headlineFieldOnDataObject)
  }

  private static isSimpleServerResponse (dataObject: any): dataObject is SimpleServerResponse {
    return !!((dataObject as unknown as SimpleServerResponse).requestStatus && (dataObject as unknown as SimpleServerResponse).message)
  }

  private static prop<T, K extends keyof T> (obj: T, key: K): string {
    return obj[key] as unknown as string
  }

  public getHeadline (): string {
    if (this.headlineValue === '500' || this.headlineValue === undefined) {
      return i18n.t('warning.500').toString()
    } else {
      return i18n.t(this.messageRoot + this.headlineValue).toString()
    }
  }

  public getInfo (): string {
    let value = TextCreatorForSubmitSuccess.prop(this.dataObject, this.infoFieldOnDataObject)
    // split message string to check if backend already sent path to error message
    const messageChunks = value.split('.')
    const chunkContainsWhitespace = messageChunks.some(text => { return text.trim().length === 0 })
    value = chunkContainsWhitespace || messageChunks.length === 1 ? this.messageRoot + value : value
    if (this.headlineValue === '500' || this.headlineValue === undefined) {
      // return i18n.t('warning.' + value).toString()
      return ''
    } else {
      return i18n.t(value, this.optionalErrorMessageObject).toString()
    }
  }

  public getErrorMessage (): string {
    return (this.headlineValue === '500' || this.headlineValue === undefined) ? this.getHeadline() : this.getInfo()
  }
}

export default TextCreatorForSubmitSuccess
