













































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import {
  GET_CURRENT_DECLARATION_CONTENT, GET_CURRENT_TERMS_OF_SERVICE_CONTENT,
  REGISTER
} from '@/store/user/actions.type'
import { SimpleServerResponse } from '@/store/types'
import TextCreatorForSubmitSuccess from '@/components/forms/getHeadlineAndInfoForSubmitSuccess'
import DeclarationModal from '@/components/common/DeclarationModal.vue'
import { Form, KeyValue } from '@/store/commonFormInput/types'
import Enums from '@/common/Enums'
import BaseForm from '@/components/forms/BaseForm.vue'
import InputTypes from '@/common/formTypes'

@Component({
  components: { BaseForm, DeclarationModal }
})
export default class Registration extends Vue {
  @Action(REGISTER)
  public register: (params: object) => Promise<SimpleServerResponse>

  @Action(GET_CURRENT_DECLARATION_CONTENT)
  public getCurrentDeclarationContent: (locale: string) => Promise<SimpleServerResponse>

  @Action(GET_CURRENT_TERMS_OF_SERVICE_CONTENT)
  public getCurrentTermsOfServiceContent: (locale: string) => Promise<SimpleServerResponse>

  termsOfService = ''
  declaration = ''
  email = ''
  password = ''
  roleSelectionState = true
  termsModel = false
  termsState: boolean | null = null

  feedbackHeadline = ''
  feedbackInfo = ''
  responseState = false
  gotResponse = false

  registrationParams: { email: string; password: string; userRole: string | (string | null)[] } = { email: '', password: '', userRole: '' }

  onInputChange (id: string | number): void {
    if (id === 'userRole') {
      this.termsModel = false
    }
  }

  onUpdateChange (id: string | number): void {
    if (id === 'userRole') {
      this.getUserRoleState()
    }
  }

  onInvalidChange (id: string | number): void {
    if (id === 'userRole') {
      this.getUserRoleState()
    }
  }

  get fields (): Form[] {
    return [
      {
        id: 'email',
        key: 'email',
        type: InputTypes.EMAIL,
        label: this.$i18n.t('register.email').toString() + ' *',
        md: 6,
        required: true,
        autofocus: true
      },
      {
        id: 'password',
        key: 'password',
        type: InputTypes.PASSWORD,
        label: this.$i18n.t('register.password').toString() + ' *',
        md: 6,
        trim: true,
        required: true,
        checkPattern: true,
        showStrength: true,
        usesDescriptionSlot: true
      },
      {
        id: 'userRoles',
        key: 'userRole',
        type: InputTypes.SELECT,
        label: this.$i18n.t('register.register-as').toString(),
        required: true,
        options: this.userRoles,
        usesDescriptionSlot: true,
        state: this.roleSelectionState
      },
      {
        id: 'dummy',
        key: 'dummy',
        type: InputTypes.DUMMY
      }
    ]
  }

  get userRoles (): KeyValue[] {
    return Enums.USER_ROLES.map(key => {
      return {
        value: key,
        text: this.$i18n.t('register.USER_ROLES.' + key).toString()
      }
    })
  }

  getUserRoleState (): void {
    this.roleSelectionState = this.registrationParams.userRole !== ''
  }

  get correctDeclaration (): string {
    this.getCurrentDeclarationContent(this.$root.$i18n.locale).then(data => {
      this.declaration = data.message
    }, error => {
      this.$root.$emit('alert', this.$i18n.t('warning.error!'), this.$i18n.t('register.' + error.message), true)
    })
    return this.declaration
  }

  get correctTermsOfService (): string {
    this.getCurrentTermsOfServiceContent(this.$root.$i18n.locale).then(data => {
      this.termsOfService = data.message
    }, error => {
      this.$root.$emit('alert', this.$i18n.t('warning.error!'), this.$i18n.t('register.' + error.message), true)
    })
    return this.termsOfService
  }

  hideModalDPD (): void {
    this.$bvModal.hide('declaration-modal-DPD')
  }

  hideModalTOS (): void {
    this.$bvModal.hide('declaration-modal-TOS')
  }

  sendRegistration (event: Event): void {
    event.preventDefault()
    const params = { email: this.registrationParams.email, password: this.registrationParams.password, role: this.registrationParams.userRole, username: this.registrationParams.email }
    let textCreator: TextCreatorForSubmitSuccess<SimpleServerResponse, keyof SimpleServerResponse>
    this.register(params).then(data => {
      textCreator = new TextCreatorForSubmitSuccess<SimpleServerResponse, keyof SimpleServerResponse>(data, 'register.')
      this.responseState = true
      this.$router.push({ name: 'login' })
    }, error => {
      textCreator = new TextCreatorForSubmitSuccess<SimpleServerResponse, keyof SimpleServerResponse>(error, 'register.', { address: this.registrationParams.email })
      this.responseState = false
    }).finally(() => {
      this.feedbackHeadline = textCreator.getHeadline()
      this.feedbackInfo = textCreator.getInfo()
      this.$root.$emit('alert', this.feedbackHeadline, this.feedbackInfo, !this.responseState)
    })
  }
}
